* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
}

body {
  box-sizing: border-box;
  background-color: #000000 !important;
}
